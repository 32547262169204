import { apiMyJobList, apiGetClassTeacher } from '@/api/api.js'
export default {
    components: {
    },
    data() {
        return {
            teachid: '',
            statimeTime: '',
            teachOptions: [],
            resultData: [],
            page: 1,
            loading: false,
            noMore: false
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getClassTeacher()
            this.getMyJobList()
        },
        getClassTeacher() {
            apiGetClassTeacher().then((res) => {
                this.teachOptions = res.data
            })
        },
        getMyJobList() {
            // this.loading = true
            let teachidValue = ''
            let statimeTimeValue = ''
            if (this.teachid == '') {
                teachidValue = -1
            } else {
                teachidValue = this.teachid
            }
            if (this.statimeTime == '') {
                statimeTimeValue = -1
            } else {
                statimeTimeValue = this.statimeTime
            }
            let paramsBase = {
                page: this.page,
                teachid: teachidValue,
                statime_time: statimeTimeValue
            }
            apiMyJobList(paramsBase).then((res) => {
                let dataValue = res.data.list
                 if (dataValue.length != 0) {
                    setTimeout(() => {
                        dataValue.forEach(element => {
                            element.show = false
                            this.resultData.push(element)
                        });
                    }, 500);
                } else {
                    this.loading = false
                    this.noMore = true
                }
            })
        },
        load() {
            this.page++
            this.getMyJobList()
        },
        changeFiltrate() {
            this.page = 1
            this.loading = true
            this.noMore = false
        },
        clickViewGo(item){
            sessionStorage.setItem('tcl_jobId', item.id)
			if(item.exe_type=='exercise_inclass'){
				this.$router.push({ name: 'UserCenterExerciseBookDetailsTwo',query: { title: item.title, start_time: item.start_time, end_time: item.end_time }})
			}else if(item.exe_type=='exercise_normal'){
				this.$router.push({ name: 'UserCenterExerciseBookDetails' })
			}
			// sessionStorage.setItem('tcl_exe_type', item.exe_type)
            // this.$router.push({ name: 'UserCenterExerciseBookDetails' })
        },
        handleSearch() {
			this.page = 1;
			this.resultData = []
			this.getMyJobList()
        },
        handleReset() {
            this.page = 1
            this.loading = true
            this.noMore = false
            this.teachid = ''
            this.statimeTime = ''
            this.resultData = []
            this.getMyJobList()
        }
    },
}